import React, { useState } from 'react';

function HomePage({ onGeneratePlans }) {
  const [subject, setSubject] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (subject.trim() === '') {
      alert('Veuillez entrer un sujet pour la dissertation.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await onGeneratePlans(subject); // Appelle la fonction onGeneratePlans passée en prop
    } catch (error) {
      setError('Une erreur est survenue lors de la génération des plans. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <section style={styles.mainSection}>
        <h1 style={styles.title}>Générer ma dissertation</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="text"
            placeholder="Entrez le sujet de votre dissertation..."
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            style={styles.input}
          />
          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? 'Génération en cours...' : 'Générer'}
          </button>
        </form>
        {error && <p style={styles.error}>{error}</p>}
      </section>
      
      {/* Section How It Works */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Comment ça marche ?</h2>
        <p style={styles.sectionContent}>
          Notre IA simplifie la rédaction de vos dissertations en plusieurs étapes :
        </p>
        <ol style={styles.list}>
          <li style={styles.justifiedText}><strong>Génération du plan :</strong> Nous vous proposons trois plans différents basés sur votre sujet.</li>
          <li style={styles.justifiedText}><strong>Choix et modification :</strong> Sélectionnez le plan qui vous convient le mieux et modifiez les titres ou ajoutez des parties selon vos besoins.</li>
          <li style={styles.justifiedText}><strong>Rédaction complète :</strong> L'IA rédige votre dissertation complète en suivant le plan que vous avez choisi.</li>
        </ol>
      </section>
      
      {/* FAQ Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>FAQ</h2>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion}>Comment puis-je utiliser le générateur de dissertation ?</h3>
          <p style={styles.faqAnswer}>
            Entrez simplement le sujet de votre dissertation dans le champ prévu et cliquez sur "Générer". Vous recevrez trois propositions de plans que vous pourrez modifier avant de générer la dissertation complète.
          </p>
        </div>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion}>Quels types de sujets puis-je utiliser ?</h3>
          <p style={styles.faqAnswer}>
            Vous pouvez entrer n'importe quel sujet académique pour lequel vous avez besoin d'une dissertation. Notre IA est conçue pour traiter une large gamme de sujets.
          </p>
        </div>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion}>La dissertation générée est-elle originale ?</h3>
          <p style={styles.faqAnswer}>
            Oui, notre IA génère un texte unique pour chaque dissertation, basé sur les informations que vous fournissez.
          </p>
        </div>
        <div style={styles.faqItem}>
          <h3 style={styles.faqQuestion}>Puis-je modifier la dissertation après génération ?</h3>
          <p style={styles.faqAnswer}>
            Vous pouvez modifier le plan avant la génération complète de la dissertation, mais une fois générée, vous devrez la modifier manuellement.
          </p>
        </div>
      </section>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#f5f5f5',
  },
  mainSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
  title: {
    fontSize: '3rem',
    color: '#333',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    width: '400px',
    padding: '15px',
    fontSize: '1.2rem',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '15px 30px',
    fontSize: '1.2rem',
    color: '#fff',
    backgroundColor: '#007BFF',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginTop: '20px',
  },
  section: {
    marginTop: '40px',
    padding: '20px',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '800px',
  },
  sectionTitle: {
    fontSize: '2rem',
    color: '#333',
    marginBottom: '15px',
  },
  sectionContent: {
    fontSize: '1rem',
    color: '#666',
    marginBottom: '15px',
    textAlign: 'justify', // Texte justifié
  },
  list: {
    listStyleType: 'decimal',
    paddingLeft: '20px',
  },
  justifiedText: {
    textAlign: 'justify', // Texte justifié pour les éléments de la liste
  },
  faqItem: {
    marginBottom: '20px',
  },
  faqQuestion: {
    fontSize: '1.2rem',
    color: '#007BFF',
  },
  faqAnswer: {
    fontSize: '1rem',
    color: '#666',
    textAlign: 'justify', // Texte justifié pour les réponses de la FAQ
  },
};

export default HomePage;
