import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import HomePage from './HomePage';
import './App.css';

function App() {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [generatedDissertation, setGeneratedDissertation] = useState('');

  const handleGeneratePlans = async (subject) => {
    try {
      const response = await fetch('https://www.dissertation-ia.fr/api/generate-plans', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subject }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate plans');
      }

      const data = await response.json();

      const allPlansContent = data.plans.join('');
      const plansArray = allPlansContent.split('@@').filter(plan => plan.trim() !== '');

      setPlans(plansArray);
      setSelectedPlan(null);
    } catch (error) {
      console.error('Error generating plans:', error);
      alert('There was an error generating the plans. Please try again later.');
    }
  };

  const handleSelectPlan = (index) => {
    setSelectedPlan(index);
  };

  const handleContentChange = (event) => {
    const updatedPlans = [...plans];
    updatedPlans[selectedPlan] = event.target.innerHTML; // Capture the inner HTML content directly
    setPlans(updatedPlans);
  };

  const renderEditableContent = (plan) => {
    return (
      <div
        id="editable-plan-container"
        contentEditable="true"
        onBlur={handleContentChange} // Handle content change when user exits the editable area
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(plan) }}
        className="editable-plan"
      />
    );
  };

  const handleSubmitPlan = async () => {
    if (selectedPlan === null) return;

    const planContent = plans[selectedPlan];
    setGenerating(true);

    try {
      const response = await fetch('https://www.dissertation-ia.fr/api/submit-dissertation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ plan: planContent }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit the dissertation');
      }

      const data = await response.json();

      // Nettoyer la chaîne en retirant les balises ```html``` et ``` qui entourent le contenu
      const cleanedDissertation = DOMPurify.sanitize(data.dissertation.replace(/```html/g, '').replace(/```/g, ''));

      setGeneratedDissertation(cleanedDissertation);
    } catch (error) {
      console.error('Error submitting dissertation:', error);
      alert('There was an error submitting the dissertation. Please try again later.');
    } finally {
      setGenerating(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Générateur de dissertation par IA</h1>
      </header>
      <main>
        {!plans.length ? (
          <HomePage onGeneratePlans={handleGeneratePlans} />
        ) : (
          <div className="plans-container">
            {plans.map((plan, index) => (
              <div
                key={index}
                className={`plan-card ${selectedPlan === index ? 'selected' : ''}`}
                onClick={() => handleSelectPlan(index)}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(plan) }}
                />
              </div>
            ))}
          </div>
        )}
        {selectedPlan !== null && (
          <div className="selected-plan-details-container">
            <h2 className="modify-plan-title">Modifier le Plan Sélectionné</h2>
            {renderEditableContent(plans[selectedPlan])}
            <button onClick={handleSubmitPlan} disabled={generating}>
              {generating ? 'Generating Dissertation...' : 'Générer'}
            </button>
          </div>
        )}
        {generatedDissertation && (
          <div className="generated-dissertation-container">
            <h2 className="generated-dissertation-title">Dissertation rédigée</h2>
            <div className="generated-dissertation" dangerouslySetInnerHTML={{ __html: generatedDissertation }} />
          </div>
        )}
      </main>
    </div>
  );  
}

export default App;
